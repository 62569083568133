import React, { Component,useState, useEffect }  from 'react';
 import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Image from 'react-bootstrap/Image'
import { NavLink } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
 import Col from 'react-bootstrap/Col';
import config from '../../constant';

 

       class Header extends Component {
    
    render(){
    return(

      <div>
 <div className='topBar  d-none d-sm-none '>
        <Container fluid>
          <Row>
            <Col lg="12" className='text-center'>
              <Navbar>
            <Nav className='mx-auto justify-content-center'>
            <li> <a  href="https://www.facebook.com/CAMline-Solutions-India-Pvt-Ltd-100223158445111/"   activeclassname="active" target='_blank'><i className='fa fa-facebook'></i></a></li> 
        <li>  <a href="https://www.linkedin.com/company/camline-security-solutions/"   activeclassname="active" target='_blank'><i className='fa fa-linkedin'></i></a></li>
          </Nav>
          </Navbar>
            </Col>
          </Row>
        </Container>
      </div>      
        <Navbar collapseOnSelect expand="lg" className="justify-content-center" >
      <Container>
        <Col lg="3">
        <Navbar.Brand> <NavLink to="/"   activeclassname="active"><Image src={config.ImageUrl+"Camline-logo-1.png"}  fluid/> </NavLink> </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='pull-right'/>
          {/* <Nav className="mx-auto justify-content-center py-3 d-none d-sm-block"  >
          <span>Certificate : ISO 9001 : 2015 | ISO 14001 : 2015 | MSME | SSI | NSIC  </span>

            
          </Nav> */}
          </Col>
          <Col lg="7">
          <Navbar.Collapse id="responsive-navbar-nav">

          <Nav className='mx-auto'>
          <NavLink to="/"   activeclassname="active">Home</NavLink>
          <NavLink to="/who_we_are"   activeclassname="active">Who We Are</NavLink>
          <NavDropdown title="Services" id="basic-nav-dropdown"  renderMenuOnMount={true} align="end">
          <NavDropdown title="System Integration" id="basic-nav-dropdown" align="end">
              <NavDropdown.Item href=""> <NavLink to="/access_control_systems"   activeclassname="active">Access Control Systems</NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/security_surveillance_systems"   activeclassname="active">Security Surveillance Systems</NavLink></NavDropdown.Item>
           
              <NavDropdown.Item href=""> <NavLink to="/fire_alarm_and_pa_system"   activeclassname="active">Fire Alarm & PA System </NavLink></NavDropdown.Item>
              <NavDropdown.Item href=""> <NavLink to="/gating_solutions"   activeclassname="active">Gating Solutions</NavLink></NavDropdown.Item>
            </NavDropdown>
  {/* <NavDropdown.Item href="" > 
  <NavLink to="/system_integration"   activeclassname="sactive">System Integration</NavLink>
  
  </NavDropdown.Item> */}

  <NavDropdown.Item href="" > <NavLink to="/consultation_project_management"   activeclassname="sactive">Consultation Project Management</NavLink></NavDropdown.Item>

 
  <NavDropdown.Item href="" > <NavLink to="/network_security_solutions"   activeclassname="sactive">Network Security Solutions</NavLink></NavDropdown.Item>
 
  <NavDropdown.Item href="" > <NavLink to="/project_amc"   activeclassname="sactive">Project AMC</NavLink></NavDropdown.Item>


  <NavDropdown.Item href="" > <NavLink to="/turnkey_project_systems"   activeclassname="sactive">Turnkey Project Systems</NavLink></NavDropdown.Item>

 </NavDropdown> 

 <NavLink to="/tech_partners"   activeclassname="active">Tech Partners</NavLink>

 <NavLink to="/presence"   activeclassname="active">Our Presence</NavLink>

              <NavLink to="/contact"   activeclassname="active">Contact Us</NavLink>
 
          </Nav>
          </Navbar.Collapse>

          </Col>
          <Col lg="2" className='d-sm-block d-none'>

          <Nav className='justify-content-center'>
        <li> <a href="https://www.facebook.com/CAMline-Solutions-India-Pvt-Ltd-100223158445111/"   activeclassname="active" target='_blank'><Image src={config.ImageUrl + "facebook-icon.png"} /></a></li> 
        <li>  <a href="https://www.linkedin.com/company/camline-security-solutions/"   activeclassname="active" target='_blank'><Image src={config.ImageUrl + "Linked-in-icon.png"} /></a></li>
 
          </Nav>
          </Col>

       </Container>
    </Navbar>
    </div>
           );
}

  }
export default Header;
