import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class NetworkSolutions extends Component {

    componentDidMount(){
        document.title = "Network Solutions: Routing and Switching | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Network Solutions: Routing and Switching' subtitle='' BkImg={config.ImageUrl + "Advantages-of-Network-Security-Banner.jpg"} /> 

 <Container className=" py-5 ProductsSection ">
    
        <Row>
            <Col lg="12">

 <p>CAMline offers an array of unmatched products that are designed and executed by experts. We provide customized solutions to adhere to every client's requirements at competitive rates. CAMline is known for its quality and timely service. We deal in high quality network solution systems and install high-end Routers and switches across the country.</p>

 <p>In addition to network design, implementation, and security solutions, CAMline also provides ongoing network management and support services. We monitor and manage network performance, troubleshoot network issues, and provide technical support to ensure the network is always up and running. Keeping all the security parameters in mind, CAMline offers the best in class solutions so that your premises are integrated with the systems.</p>

 <p>Our managed infrastructures for larger enterprise and campus networks ensures connection among end-users, services, servers, and storage through highly flexible core, distribution, and access layers.
</p>


            </Col>
        </Row>

    </Container>


 

     
   
 <section className='advantageSection pb-5'>
 <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Why Should You Deploy Network Solutions?</h1>

        </div>
        <Row>
            <Col lg="6">
                <div className='advantageDiv'>
                    <h4>Improved Connectivity and Communication </h4>
                    <p>A robust network infrastructure ensures reliable data transfer, efficient collaboration, and effective sharing of resources such as files, documents, and applications. A well-designed network solution can also support various communication channels, including voice, video, and messaging, enabling employees to connect and collaborate effectively regardless of their physical location.


</p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Scalability and Flexibility  </h4>
                    <p>As your business expands, a well-designed network infrastructure can accommodate increasing demands for bandwidth, user connections, and network resources. Whether you need to support additional devices, integrate new applications, or expand your operations to different locations, a scalable network solution can provide the necessary infrastructure to meet these requirements. 



 </p>
                </div>
            </Col>
        </Row>

        <Row>
            
            <Col lg={{span:6 , offset:3}} >
            <div className='advantageDiv'>
                    <h4>Cost Efficiency</h4>
                    <p>Centralized resource sharing reduces the need for duplicate hardware and software, lowering procurement and maintenance costs. Efficient collaboration and communication tools minimize travel expenses and enable remote work opportunities. Additionally, streamlined workflows and improved productivity result in overall cost efficiency.
</p>
                </div>
            </Col>
        </Row>
        
    </Container>
 </section>


        <Footer/>
        </div>
    );
} 
}
export default NetworkSolutions;