import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class ProjectManagement extends Component {

    componentDidMount(){
        document.title = "Consultation Project Management | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Consultation Project Management' subtitle='' BkImg={config.ImageUrl + "Consultation-Project-Management-Banner.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        Our experience in project execution over these years has made us aware of all the nitty-gritty of security systems and this helps organizations with their security-related issues. Right from risk management to system architecture and installation, we help them execute the right way at every step. Whether it is a commercial or a residential complex, we list down all the possible threats from every checkpoint. Mapping all the ideal requirements helps the organization arrange for the right budgets and infrastructure too.
</p> 
        <p>     
The team helps you design a strategy that is customized per the specific environment. Our experienced project managers will help you plan, coordinate, and execute each project with precision and accuracy. From project scoping and planning to resource allocation and reporting, we’ll ensure that your security projects are delivered on time.


</p> 
 
   
                              </Col>
       

    </Row>

  
</Container>


 
<section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Advantages of Consultation Project Management</h1>

        </div>
        <Row>
            <Col lg="6">
                <div className='advantageDiv'>
                    <h4> Expertise</h4>
                    <p>Consulting comes with knowledge and guidance that can help fill every gap related to physical security. Consultant understand and analyze every possible potential threat and suggest mitigating solutions. Their valuable insights into the latest threats and vulnerabilities, can help apply best practices for security.</p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Cost-effectiveness:</h4>
                    <p>Hiring a consultant is more cost-effective than hiring a full-time security professional. Consultants can be engaged on specific projecs, allowing you to tailor their services to your specific needs and budget.
 </p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col  lg={{span:6 , offset:3}}>
            <div className='advantageDiv'>
                    <h4> Compliance</h4>
                    <p>A security consultant can help ensure that your organization meets regulatory and compliance requirements. They can identify areas where your organization may be falling short and help you identify and address issues before they can turn into problems. </p>
                </div>
            </Col>
            
        </Row>
    </Container>
    </section>     
        <Footer/>
        </div>
    );
} 
}
export default ProjectManagement;