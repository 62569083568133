import React, { Component } from 'react';
import Header from '../components/layout/header';
import Contactform from '../components/section/contactform';
import Footer from '../components/layout/footer';
import Googlemap from '../components/section/googlemap';

import config from '../constant';
import Mainmap from '../components/section/MainMap';
import { Col, Container, Image, Row } from 'react-bootstrap';


class Branches extends Component {

  
    componentDidMount(){
        document.title = "Branches | " + config.SITE_NAME;
      }

    render(){
        return(
            <div>
            <Header/>   
             {/* <CardSec/>     */}
           <Container className='text-center py-5'> 

           <p>CAMline Security Provides services and Solutions in all major locations across India. We are providing prompt services support all over India with in-house service support Team.</p>
           
           
           <Image src={config.ImageUrl+"Presences-Map.png"} fluid alt={config.SITE_NAME}></Image>
           </Container> 

          <section className='serviceSec'>
           <Container className='py-5 chartDiv'>
             <Row>
              <Col lg="4" className='pt-5'>
                <h4>Whether it is a small or large project, CAMline has done it all.</h4>

                
                <p><span className='green'>Team Strength</span><br></br>19+ trained and certified resources with their specialty</p>

              
                <p>  <span className='green'>InHouse Team Presence</span><br></br>PAN INDIA</p>

                <p>  <span className='green'>Master</span><br></br>Access Control, IDS, Barrier</p>
              </Col>
              <Col  lg="8">
                 <Image src={config.ImageUrl+"Our-Chart.png"} fluid alt={config.SITE_NAME}></Image>
</Col>

            </Row>
           </Container>
           </section>
             <Footer/>

            </div>
        )
    }
}

export default Branches;