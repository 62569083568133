import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Oem_partner extends Component {

    componentDidMount(){
        document.title = "Tech Partners | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Tech Partners' subtitle='' BkImg={config.ImageUrl + "System-Integration-Banner.jpg"} /> 



<section className="hrDiv">

<Container   className='py-5  OemImage'>
{/* <Row>
                <Col>
                <h1 className=" pb-sm-5 py-xs-4  mt-0  text-left">Surveillance System</h1>

                </Col>
                </Row> */}


                <Row className='py-3'>
                    <Col lg="2">
                <h1 className=" py-xs-4  mt-0  text-left">Surveillance System</h1>

                    </Col>

                    <Col lg="10">
<Row>
        <Col   lg={{span:2, offset:1}} xs="6">
            <a href='https://www.bosch.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "4.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.honeywell.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "2.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.hikvision.com/en/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "10.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.univiewtechnology.com/' target='_blank'> <Image src={config.ImageUrl+"/Partner/"+  "12.jpg"} fluid></Image></a>
        </Col>
        <Col   lg={{span:2, offset:0}}   xs={{span:6, offset:0}}>
         <a href='https://www.axis.com/en-in' target='_blank'> <Image src={config.ImageUrl+"/Partner/"+  "8.jpg"} fluid></Image></a>
        </Col>
    </Row>
                    </Col>
                </Row>
    
 </Container>
<hr></hr>


<Container className='py-5  OemImage'>
{/* <Row>
                <Col>
                <h1 className=" pb-sm-5 py-xs-4  mt-0  text-left">Access Control</h1>

                </Col>
                </Row> */}

                <Row className='py-3'>
                    <Col lg="2">
                <h1 className="    mt-0  text-left">Access Control</h1>

                    </Col>

                    <Col lg="10">

    <Row>
        <Col   lg={{span:2, offset:1}} xs="6">
            <a href='https://www.hidglobal.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "7.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.honeywell.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "2.jpg"} fluid></Image></a>
        </Col>
        <Col    lg={{span:2, offset:0}}   xs={{span:6, offset:0}}>
        <a href='https://www.esslsecurity.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "6.jpg"} fluid></Image></a>
        </Col>   
    </Row>
    </Col>
    </Row>
 
</Container>
<hr></hr>


  
<Container className='py-5  OemImage'>
{/* <Row>
                <Col>
                <h1 className=" pb-sm-5 py-xs-4  mt-0  text-left">Fire System & PA</h1>

                </Col>
                </Row> */}
                <Row className='py-3'>
                    <Col lg="2">
                <h1 className="    mt-0  text-left">Fire System & PA</h1>

                    </Col>

                    <Col lg="10">

    <Row>
        <Col   lg={{span:2, offset:1}} xs="6">
            <a href='https://www.edwardsfiresafety.com/en/us/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "15.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.honeywell.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "2.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://ravelfire.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "20.jpg"} fluid></Image></a>
        </Col>   

        <Col lg="2" xs="6">
        <a href='https://www.bosch.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "4.jpg"} fluid></Image></a>
        </Col>   

        <Col   lg={{span:2, offset:0}}   xs={{span:6, offset:0}}>
        <a href='https://www.ahujaradios.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "5.jpg"} fluid></Image></a>
        </Col>   
    </Row>
    </Col>
    </Row>
</Container>
<hr></hr>



<Container className='py-5  OemImage'>
{/* <Row>
                <Col>
                <h1 className=" pb-sm-5 py-xs-4  mt-0  text-left">IDS </h1>

                </Col>
                </Row> */}
      <Row className='py-3'>
                    <Col lg="2">
                <h1 className="    mt-0  text-left">IDS</h1>

                    </Col>

                    <Col lg="10">

    <Row>
        <Col   lg={{span:2, offset:1}} xs="6">
            <a href='https://www.securicoelectronics.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "11.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.texe.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "1.jpg"} fluid></Image></a>
        </Col>
         
    </Row>
    </Col>
    </Row>

</Container>
<hr></hr>




<Container className='py-5  OemImage'>
{/* <Row>
                <Col>
                <h1 className=" pb-sm-5 py-xs-4  mt-0  text-left">Gating System </h1>

                </Col>
                </Row> */}
   <Row className='py-3'>
                    <Col lg="2">
                <h1 className="    mt-0  text-left">Gating System</h1>

                    </Col>

                    <Col lg="10">

    <Row>
        <Col   lg={{span:2, offset:1}} xs="6">
            <a href='https://www.magnetic-access.com/en/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "17.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.perco.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "3.jpg"} fluid></Image></a>
        </Col>
        <Col   lg={{span:2, offset:0}}   xs={{span:6, offset:0}}>
        <a href='https://ravelfire.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "20.jpg"} fluid></Image></a>
        </Col>  
    </Row>
    </Col>
    </Row>

</Container>

<hr></hr>




<Container className='py-5  OemImage'>
{/* <Row>
                <Col>
                <h1 className=" pb-sm-5 py-xs-4  mt-0  text-left">Routing & Switches </h1>

                </Col>
                </Row> */}
    <Row className='py-3'>
                    <Col lg="2">
                <h1 className="    mt-0  text-left">Routing & Switches</h1>

                    </Col>

                    <Col lg="10">

    <Row>
        <Col   lg={{span:2, offset:1}} xs="6">
            <a href='https://www.cisco.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "14.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.arubanetworks.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "13.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.tp-link.com/in/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "21.jpg"} fluid></Image></a>
        </Col>  
        <Col lg="2" xs="6">
        <a href='https://www.netgear.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "19.jpg"} fluid></Image></a>
        </Col>  
    </Row>
    </Col>
    </Row>
</Container>

<hr></hr>




<Container className='py-5  OemImage'>
{/* <Row>
                <Col>
                <h1 className=" pb-sm-5 py-xs-4  mt-0  text-left">VMS  </h1>

                </Col>
                </Row> */}


<Row className='py-3'>
                    <Col lg="2">
                <h1 className="    mt-0  text-left">VMS</h1>

                    </Col>

                    <Col lg="10">

     <Row className='pb-4'>
        <Col   lg={{span:2, offset:1}} xs="6">
            <a href='https://www.milestonesys.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+ "18.jpg"} fluid></Image></a>
        </Col>
        <Col lg="2" xs="6">
        <a href='https://www.genetec.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "16.jpg"} fluid></Image></a>
        </Col>
        <Col   lg={{span:2, offset:0}}   xs={{span:6, offset:0}}>
        <a href='https://trassir.com/' target='_blank'><Image src={config.ImageUrl+"/Partner/"+  "22.jpg"} fluid></Image></a>
        </Col>  
    </Row>

    </Col>
                </Row> 
</Container>
</section>

        <Footer/>
        </div>
    );
} 
}
export default Oem_partner;