import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Image from 'react-bootstrap/Image'

import { images } from './service'; 

const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: false,
    autoplay: true,
    navText: ['<img src="assets/images/hotel/Left-Arrow.png">', '<img src="assets/images/hotel/Right-Arrow.png">'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};
    class Owlslider extends Component {
    
    render() {
    return(
        <div className='AboutBg' style={{ 
            backgroundImage: `url(assets/images/hotel/bannar_04.jpg)` 
          }} >
        <Container className="py-4">
            <h1 className="py-2">Speciality</h1>
            <Row className="justify-content-center text-center">
            {/* <Col xs lg="6">1 of 2</Col> */}
            <Col xs lg="12">
        <OwlCarousel className='owl-theme'  {...options}>
        
        {images.map( (val , i) => (  
               
    <div className='item' key={i}>
    {/* <Image src={val.imgUrl} alt={`${val.imgCaption}`} fluid />    */}

<div className="productDiv" style={{ 
      backgroundImage: `url( ${val.imgUrl} )` 
    }} >
					        <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>
 					     </div>
				         </div>
                         </div>
                         )  )}

</OwlCarousel>
</Col>
</Row>
</Container>
</div>
    );
}

}



export default Owlslider;