import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import AboutServices from '../components/section/about_us';


 class Home extends Component {

    componentDidMount(){
        document.title = "Home | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <Slider /> 
         <AboutHome/>
         <AboutServices/>
      
 
        <Footer/>
        </div>
    );
} 
}
export default Home;