import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import {Fade} from 'react-awesome-reveal';

class AboutHome extends Component {
        render() {

    return(
        <>
    <Container className='AboutSec py-3'  >

        
        <Row className='py-5'>
            <Col lg="7">
            <Fade direction='left'  delay="40" triggerOnce="true">

            <h1 className=" pb-3  mt-0  text-left">Premium Security Partner In India</h1>

                <p>We are experts in designing, implementing, and supporting modern security and access control systems, video surveillance, and public address systems. Our integrated solutions are specifically tailored to collect incident information and initiate actions that effectively mitigate risks to both assets and human safety during emergency situations.</p>

                <p> Our designs focus on seamlessly integrating these systems to ensure efficient data collection and swift response capabilities, ultimately minimizing the impact of emergencies.</p>
                <p>Our team is dedicated to providing you with the highest level of support throughout the entire process. From system design and installation to ongoing maintenance, we are committed to delivering reliable and effective solutions that meet your unique security needs.</p>
                </Fade>
            </Col>
            <Col lg={{span:4,offset:1 }}>
            <Fade direction='right'  delay="40" triggerOnce="true">

                <Image src={config.ImageUrl+"About-us-Image.png"} fluid alt={config.SITE_NAME}></Image>
                </Fade>
            </Col>
        </Row>
    </Container>


 
        </>

    );
}
    }
export default AboutHome;