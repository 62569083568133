import React, { Component, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
 import Form from 'react-bootstrap/Form';
import config from '../../constant';
 import FloatingLabel from 'react-bootstrap/FloatingLabel';
import SubHeader from '../layout/subheader';
import { Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
    
    function validate(evt) {
        var theEvent = evt || window.event;
        var key = theEvent.keyCode || theEvent.which;
        key = String.fromCharCode( key );
        var regex = /[0-9]|\./;
        if( !regex.test(key) ) {
          theEvent.returnValue = false;
          if(theEvent.preventDefault) theEvent.preventDefault();
        }
    }

  
      const Contactform = () => {
 
       
        const [double, setDouble] = useState(false);


        const [mailerState, setMailerState] = useState({
          name: "",
          phone:"",
          email: "",
          message: "",
        });
    
        function handleStateChange(e) {
          setMailerState((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
          }));
        }
    
        const submitEmail = async (e) => {

           e.preventDefault();
           setDouble(true);

          //console.log({ mailerState });
          const response = await fetch(config.BASE_URL+"send_form/", {
            
            method: "POST",
            headers: {
              "Content-type": "application/json"
            },
            body: JSON.stringify({ mailerState }),
          })
            .then((res) => res.json())
            .then(async (res) => {
              const resData = await res;
              console.log(resData);
              if (resData.status === "success") {
                //alert("Message Sent");
                toast.success("Message Sent!" , {autoClose: 2000})

              } else if (resData.status === "fail") {
                //alert("Message failed to send");
                toast.success("Message failed to send!", {autoClose: 2000})

              }
            })
            .then(() => {
              setMailerState({
                email: "",
                name: "",
                phone:"",
                message: "",
              });
              setDouble(false);

 
            });
            
        };
    
         return(
          <>
             <SubHeader  Title='Contact' subtitle='' BkImg={config.ImageUrl+'contact.jpeg'} /> 

             <Container  >
             <ToastContainer />
            <Row  className="contactUS py-5">

  
 <Col  sm={{order:1, span:3}} xs={{order:2, span:12}}>
          <div className='adBox'>
          <i className="fa fa-envelope"></i>
       <h4>Email</h4>
<p>   <a href={"mailto:" + config.email_id}>{config.email_id} </a>  </p>
</div>
</Col>

<Col   sm={{order:2, span:6}}  xs={{order:1, span:12}}>

<div className='adBox'>
<i className="fa fa-building"></i>
<h4 >Address</h4>

<p>  CAMline Building, 2nd floor S no 10 H, 4, Sinhgad Rd,<br></br> near Deepak Sweet Mall,  Vadgaon Khurd, Nanded Fata,<br></br>  Pandurang Industrial Area, Pune, Maharashtra 411041  
</p>
</div>
</Col>
<Col   sm={{order:3, span:3}} xs={{order:3, span:12}}>
<div className='adBox'>
<i className="fa fa-mobile"></i>
      <h4>Phone Office</h4>  
<p>  <a href={"tel:" + config.contact1}> {config.contact1}</a>  </p>
</div>
                     </Col>
            </Row>
                <Row className='pb-5'>
                    <Col lg="6" className=' text-left'>
                        <div className='adBox'>
                        <h1  className="py-3">Contact Us</h1>
                        <Form onSubmit={submitEmail} >
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Name</Form.Label>
                                
                                <Form.Control type="text" required placeholder="name " onChange={handleStateChange}
 name="name"  value={mailerState.name} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control type="text" required maxLength={10} minLength={10} onKeyPress={validate} onChange={handleStateChange}
 name="phone"  value={mailerState.phone} placeholder="88888 55555" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" required placeholder="name@example.com" onChange={handleStateChange}
           name="email"
           value={mailerState.email} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} onChange={handleStateChange}
           name="message"     value={mailerState.message}/>
                            </Form.Group>
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <button type="submit" className='btn btn-danger'  disabled={double}
  >    Submit

    </button>
                            </Form.Group>

                            </Form>
                            </div>
                    </Col>

                    <Col lg="6">
                        <div className='adBoxImage'    style={{ 
      backgroundImage: `url( ${config.ImageUrl+"Contact-us-form-image.jpg"} )` 
    }} >

                        </div>
                     </Col>
             
                </Row>
            </Container>

 
            </>

            
         )
    }


export default Contactform;