const config = { 
    BASE_URL : "https://server.developer.bicads.com/",
    // BASE_URL : "http://localhost:3001/",
SITE_NAME : 'CAMline Security Solutions',
contact1:'+91 845 986 3260',
contact2:'9028046401',
email_id:'info@camline.in',
ImageUrl:'assets/images/camline/',

 
     
}
export default config;