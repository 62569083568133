import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import Owlslider from './owlslider';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import {Fade} from 'react-awesome-reveal'
import { Button } from 'react-bootstrap';
import ClientSlider from './clientSlider';
import PartnerSlider from './partnerSlider';

export const images = [
    {
        imgUrl: config.ImageUrl+'Service-image-1.jpg',
        imgCaption:'System Integration',
        imgDesc:'System integration especially in the security space plays a very important role. It creates a platform for all the interactions between various applications or systems in one place, thereby increasing the efficiency and turn-around time for any task. ',
        url:'system_integration'
    },
    {
        imgUrl: config.ImageUrl+'Service-image-2.jpg',
        imgCaption:'Project Management',
        imgDesc:'Our experience in project execution over these years has made us aware of all the nitty-gritty of security systems and this helps organizations with their security-related issues. Right from risk management to system architecture and installation, we help them execute the right way at every step.',
        url:'consultation_project_management'
    },
    {
        imgUrl: config.ImageUrl+'Service-image-4.jpg',
        imgCaption:'Network Security Solutions',
        imgDesc:'Network security or cybersecurity is one of the most critical aspects of any business today. Statistics show that around 236.1 million ransomware attacks occurred globally in the first half of 2022. Hence it is essential to set up a secured network to ensure there is absolute security.',
        url:'network_security_solutions'
    },
    {
        imgUrl: config.ImageUrl+'Service-image-3.jpg',
        imgCaption:'Project AMC',
        imgDesc:'We strive to ensure that your systems are running optimally by providing both comprehensive and non-comprehensive system maintenance services. We use a proactive approach that involves regular preventative maintenance ',
        url:'project_amc'
    },
    {
        imgUrl: config.ImageUrl+'Service-image-5.jpg',
        imgCaption:'Turnkey Project Systems ',
        imgDesc:'Turnkey security systems offer end to end solutions and are ready-to-use systems that can provide high level of security for commercial and residential spaces. These systems are comprised of cameras, sensors, alarms, and other tools.',
        url:'turnkey_project_systems'
    },
]



const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    // navText: ['<img src="assets/images/hotel/Left-Arrow.png">', '<img src="assets/images/hotel/Right-Arrow.png">'],
    smartSpeed: 1000,
    responsive: {
        0: {
            items: 1,
        },
        400: {
            items: 1,
        },
        600: {
            items: 2,
        },
        700: {
            items: 3,
        },
        1000: {
            items: 3,

        }
    },
};
class AboutServices extends Component {
        render() {

    return(
        <>
     
     <section className='serviceSec '>

    <Container className='py-5'>
        <Row>

        <h1 className=" pb-5  text-center">Our Services & Solutions</h1>

      

        <OwlCarousel className='owl-theme'  {...options}>
        
        {images.map( (val , i) => (  
             <Fade direction='up'  delay={i  *  40 } triggerOnce="true">
          
    <div className='item' key={i}>
    {/* <Image src={val.imgUrl} alt={`${val.imgCaption}`} fluid />    */}

    <div className='serviceDiv'>
            <div className="productDiv" style={{ 
      backgroundImage: `url( ${val.imgUrl} )`  }} >		       
				         </div>
                         <div className="captionDiv">
						    <h3>{`${val.imgCaption}`} </h3>

                            <p>{`${val.imgDesc}`} </p>
                            <p><NavLink to={`${val.url}`} >Read More</NavLink> </p>

 					     </div>
                          </div>
                         </div>
                         </Fade>
                         )  )}

</OwlCarousel>
             
        </Row>
    </Container>


     </section>


    <section  className='companyProgress' style={{ 
      backgroundImage: `url( ${config.ImageUrl+"Company-Progress.jpg"} )`  }}>
        <Container>
            <Row className='text-center'>

                <Col lg="3" >
                <Fade direction='up'  delay="40" triggerOnce="true">
                    <Image src={config.ImageUrl+"1-Employee.png"} />
                    <h1>30+</h1>
                    <p>employees</p>
                    </Fade>
                </Col>

                <Col lg="3">
                <Fade direction='up'  delay="80" triggerOnce="true">
                <Image src={config.ImageUrl+"2-Project-Executed.png"} />

                <h1>1500+</h1>
                    <p>Project Executed</p>
                    </Fade>

                </Col>
                <Col lg="3">
                <Fade direction='up'  delay="120" triggerOnce="true">
                <Image src={config.ImageUrl+"3-OEM.png"} />

                <h1>10+</h1>
                    <p>years of experience</p>
                    </Fade>

                </Col>

                <Col lg="3">
                <Fade direction='up'  delay="120" triggerOnce="true">
                <Image src={config.ImageUrl+"4-Experience-Expertized-team.png"} />

                <h1>50 + </h1>
                    <p>OEM Partners</p>
                    </Fade>

                </Col>
            </Row>
        </Container>
    </section>

    <section className='testimonials py-5'>
    <Container>
            <Row >

                <Col lg="6" className='text-center'>
                <Fade direction='left'  delay="40" triggerOnce="true">
 <h2>PAN India Presence with  <br></br>In-house Service Support Team </h2>
 </Fade>
                </Col>
                <Col lg="6" className='text-left'>
                    <div className='px-5'>
                    <Fade direction='right'  delay="60" triggerOnce="true">

                    <h4>96% client satisfaction rate</h4>
                    <Image src={config.ImageUrl+"star.png"} fluid alt={config.SITE_NAME}></Image>
                    <p >Testimonails verified</p>
                    </Fade>
                    </div>
                </Col>
                </Row>
                </Container>
    </section>

    <PartnerSlider/>


    <Container className='reasonsSec py-5 d-none'>
        <Row>
            <Col lg="12">
            <h1 className=" pb-3  mt-0  text-center">4 Reasons to be Safe</h1>

            </Col>
            
        </Row>

        <Row className='py-sm-3'>
            <Col lg="6">
                 
            <Fade direction='left'  delay="40" triggerOnce="true">
    <Image  src={config.ImageUrl+"Icon-1.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Guard Your Area Smartly</h4>
                    <p>Guard Your Area Smartly Security Solutions that can deploy real-time video recording capabilities.</p>
                    </div>
                    </Fade>
             
            </Col>
            <Col lg="6">
            <Fade direction='right'  delay="80" triggerOnce="true">

            <Image  src={config.ImageUrl+"Icon-2.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Urgent Public Announcement</h4>
                    <p>Emergency situations need announcements to ensure everyone’s security.</p>
                    </div>
                    </Fade>
                    </Col>
        </Row>

        
        <Row className='py-sm-3'>
            <Col lg="6">
            <Fade direction='left'  delay="120" triggerOnce="true">

                    <Image  src={config.ImageUrl+"Icon-3.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Authorized Access</h4>
                    <p>Keep an eye to know every authorized and unauthorized entries and exits in your vicinity.</p>
                    </div>
                    </Fade>
             
            </Col>
            <Col lg="6">
            <Fade direction='right'  delay="160" triggerOnce="true">

            <Image  src={config.ImageUrl+"Icon-4.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Always Beware</h4>
                    <p>Security systems that will keep you and your surroundings safe.</p>
                    </div>
                    </Fade>
                    </Col>
        </Row>
    </Container>


<section className='whyChoose'>
    <Container className='AboutSec py-3'  >
<Row className='py-5'>
    <Col lg="5" className='py-5'>
    <Fade direction='right'  delay="40" triggerOnce="true">

    <h1 className=" pb-3  mt-0  text-left">Why Camline?</h1>
        <p>As one of the largest System Integrators, Camline provides high quality and cost effective electronic security services in India.</p>
        {/* <p>Digital security is about protecting the confidentiality, integrity, and availability of information in a computer system. We provide services that help clients maintain control of their intellectual property and brand reputation in the digital world.</p>

        <p>Over the years we have been deploying modern technology in security and access control systems, video surveillance, and public address systems. We design systems that essentially collects incident information and carries out actions that lower asset and human risk in emergency situations.</p> */}
        </Fade>
    </Col>
    <Col lg={{span:6, offset:1 }}>
    <Fade direction='left'  delay="40" triggerOnce="true">

        <Image src={config.ImageUrl+"About-Camline-min.png"} fluid alt={config.SITE_NAME}></Image>
        </Fade>
    </Col>
</Row>
</Container>
</section>


<section className='clarity py-5 text-center'>
    <Container>
            <Row>
                <Col>
                <h5>We follow some steps so that there is absolute clarity between us and our clients:</h5>
                </Col>
                </Row>

                <Row className='pt-4'>
                    <Col  sm={{span:2 , offset:1}} xs="4">    
                            <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"Bottom-Icon-1.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> A single point of contact</p>
                    </Fade>
                    </Col>
                    <Col  sm="2" xs="4">  <Fade direction='up'  delay="80" triggerOnce="true"><Image src={config.ImageUrl+"Bottom-Icon-2.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Predictable costs</p>
                    </Fade> </Col>
                    <Col  sm="2" xs="4">  <Fade direction='up'  delay="120" triggerOnce="true"><Image src={config.ImageUrl+"Bottom-Icon-3.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>Inventory management</p>
                    </Fade> </Col>
                    <Col  sm="2" xs="4"> <Fade direction='up'  delay="160" triggerOnce="true"> <Image src={config.ImageUrl+"Bottom-Icon-4.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>Standardized system design</p>
                    </Fade> </Col>
                    <Col  sm={{span:2, offset:0}}  xs="4"> <Fade direction='up'  delay="200" triggerOnce="true"> <Image src={config.ImageUrl+"Bottom-Icon-5.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>Professional reports</p>
                    </Fade> </Col>
                    <Col   sm={{span:2, offset:4}} xs="4"> 
                    <Fade direction='up'  delay="240" triggerOnce="true"> <Image src={config.ImageUrl+"Bottom-Icon-6.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>Project coordination</p>
                    </Fade> </Col>
                    <Col      sm={{span:2, offset:0}}  xs={{ span:4, offset:4}}> <Fade direction='up'  delay="280" triggerOnce="true"> <Image src={config.ImageUrl+"Bottom-Icon-7.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Consistent service deliver</p>
                    </Fade> </Col>
                </Row>
                </Container>
    </section>



    <ClientSlider/>


     {/* <Container className='clients py-5 text-center'>
            <Row>
                <Col>
                <h1 className=" pb-3  mt-0  text-left">Our Clients</h1>

                </Col>
                </Row>

                <Row className='pt-4'>
                    
                    <Col> <Fade direction='up'  delay="40" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-1.png"} fluid alt={config.SITE_NAME}></Image></Fade>
                     </Col>
                    <Col><Fade direction='up'  delay="80" triggerOnce="true"> <Image src={config.ImageUrl+"Client-icon-2.png"} fluid alt={config.SITE_NAME}></Image></Fade>
                     </Col>
                    <Col> <Fade direction='up'  delay="120" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-3.png"} fluid alt={config.SITE_NAME}></Image>
                     </Fade> </Col>
                    <Col> <Fade direction='up'  delay="160" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-4.png"} fluid alt={config.SITE_NAME}></Image>
                     </Fade> </Col>
                    <Col> <Fade direction='up'  delay="200" triggerOnce="true"><Image src={config.ImageUrl+"Client-icon-5.png"} fluid alt={config.SITE_NAME}></Image>
                     </Fade> </Col>



                </Row>
                </Container> */}
         </>

    );
}
    }
export default AboutServices;