import React, { Component }  from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { NavLink } from 'react-router-dom';
import config from '../../constant';
import { useState } from 'react';

const Footer = () => {

  const [isActive, setIsActive] = useState( );
  let getYear = () => {
    let currentYear = new Date().getFullYear();
    return currentYear;
};
const handleClick = event => {
// 👇️ toggle isActive state on click
setIsActive(current => !current);
};

     return(
      <>
        <div className='footerSection'>
          {/* row-cols-4  */}
     <Container className='py-4'> 
     <footer className="row      ">
    <Col lg="3" >
      <a href="/" className="d-flex align-items-center mb-3 link-dark text-decoration-none">
       </a>
      <p className="text-white"><Image src={config.ImageUrl+"Camline-logo-2.png"}  fluid/></p>

     
    </Col>

    

    <Col lg="5" className='addressDiv'>
      <h5>Address</h5>
      <p><span className='imageF'><Image src={config.ImageUrl + "home.png" } /></span> <span className='para'> CAMline Building 2 nd floor S no 10 H, 4,  Sinhgad Rd, near Deepak Sweet Mall,  Vadgaon Khurd, Nanded Fata,  Pandurang Industrial Area, Pune, Maharashtra 411041</span> </p>
<p><span className='imageF2'><Image src={config.ImageUrl + "email.png" } /></span>  <a href={"mailto:" + config.email_id}>{config.email_id}</a>  
<br className='d-block d-sm-none'></br> <br className='d-block d-sm-none'></br> 
<span className='imageF2'><Image src={config.ImageUrl + "phone.png" } /></span> <a href= {"tel:" + config.contact1}  >{config.contact1}</a>  </p>
    </Col>

    <Col lg="2">
      <h5>Quick Links</h5>
      <ul className="nav flex-column">
        <li className="nav-item mb-2"> <NavLink to="/" >Home</NavLink>
</li>
        <li className="nav-item mb-2">  <NavLink to="/about" >About</NavLink></li>
        <li className="nav-item mb-2">  <NavLink to="/contact" >Contact</NavLink></li>
         
       </ul>

        
 
       
    </Col>

    <Col lg="2">
      <h5>Follow us</h5>
     

        
       <p> <a href="https://www.facebook.com/CAMline-Solutions-India-Pvt-Ltd-100223158445111/"   activeclassname="active" target='_blank'><Image src={config.ImageUrl + "facebook.png"} /></a> <a href="https://www.linkedin.com/company/camline-security-solutions/"   activeclassname="active" target='_blank'><Image src={config.ImageUrl + "Linkedin.png"} /></a></p> 
        
 
       
    </Col>
  </footer>

  
    </Container>

        </div>

<div className='copyright py-3'>
  <Container><Row>
<Col lg='6' >
<p className='text-center'>Copyright ©️ {getYear()} {config.SITE_NAME}. All Rights reserved.</p>

</Col>
<Col  lg='6'>
<p className='text-center'>  Developed by <a href="https://bicads.com/" target="_blank">BICADS</a></p>
</Col>
</Row></Container>
</div>

<a href="https://api.whatsapp.com/send?phone=+918459863260&amp;text=Hello we are interested in camline security solutions." className="float effetMoveGradient " target="_blank">

<i className="fa fa-whatsapp my-float "></i>

</a>
 
{/* <a href="https://www.instagram.com/babjifiltrations/" className="float_left effetMoveGradient " target="_blank">

<i className="fa fa-instagram my-float "></i>

</a> */}
</>
    );

}

export default Footer;
