import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class ProjectAmc extends Component {

    componentDidMount(){
        document.title = "Project AMC | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Project AMC' subtitle='' BkImg={config.ImageUrl + "Project-AMC.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        At CAMline Security Solutions, we strive to ensure that your systems are running optimally by providing both comprehensive and non-comprehensive system maintenance services. We use a proactive approach that involves regular preventative maintenance checks to identify and solve any potential issues before they become serious. This allows us to minimize system downtime and ensure that any problems are dealt with quickly.



</p> 
 
   
                              </Col>
       

    </Row>

  
</Container>


 <section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Type of AMC's</h1>

        </div>
        <Row>
            <Col lg="6">
                <div className='advantageDiv'>
                    <h4> Comprehensive AMC</h4>
                    <p>In an AMC, we take the responsibility for analyzing faults and breakdowns in the hardware, as well as doing repairs and replacements of spare parts. Additionally, preventive maintenance is performed at the frequency that has been predetermined and agreed upon.
</p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Non-Comprehensive AMC</h4>
                    <p>The only difference between a Comprehensive and a Non-Comprehensive AMC is that the cost of the spare parts has to be borne by the customer or the client. Only check-ups and services are included under this. Our consultant creates a report of all the work done and suggests measures in case of a hardware issue.

 </p>
                </div>
            </Col>
        </Row>
        
    </Container>
    </section>  


    <Container className=' pb-5 text-center components' >
    <div className='text-center'>
        <h1 className="  py-5  mt-0">What comes under an AMC Site Inspection?</h1>

        </div>
        <Row>
            <Col  lg={{span:2, offset:1}}>  <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"1-Site-Inspection.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Site Inspections</p></Fade></Col>
            <Col lg="2">  <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"2-Service-Call.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Service calls</p></Fade></Col>
            <Col lg="2"> 
             <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"3-Onsite-Team-View-Support.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Onsite Team Viewer Support</p>
                    
                    </Fade>
                    </Col>
            <Col lg="2">  <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"4-Technical-Trainin Support.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>Technical and Training Support</p>
                    </Fade></Col>

                    <Col lg="2">
              <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"5-Product-Handling Support.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>  Product Handling Support</p></Fade></Col>
        </Row>

        <Row className='py-4'> 
        {/* <Col >
              <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"5-Product-Handling Support.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>  Product Handling Support</p></Fade></Col> */}
            <Col lg={{span:2, offset:4}}>  <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"6-Data Security Support.png"} fluid alt={config.SITE_NAME}></Image>
                    <p>  Data Security Support</p></Fade></Col>
            <Col lg="2"> 
             <Fade direction='up'  delay="40" triggerOnce="true">
 <Image src={config.ImageUrl+"7-Total-Secure-Maintenance.png"} fluid alt={config.SITE_NAME}></Image>
                    <p> Total Secure Maintenance</p>
                    
                    </Fade>
                    </Col>
           
        </Row>
        </Container>   




        

 <section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Why should you opt AMC's?  </h1>

        </div>
        <Row>
            <Col lg="6">
                <div className='advantageDiv'>
                    <h4> Regular Maintenance</h4>
                    <p>An AMC ensures that your security system is well-maintained and optimized periodically. As a result, any potential issue or fault can be detected and rectified as soon as it is identified and thus reduces the risk of breakdown.

</p>
                </div>
            </Col>
            <Col lg="6">
            <div className='advantageDiv'>
                    <h4> Priority Support</h4>
                    <p>An AMC comes with the benefit of priority from the service provider. This implies that clients would receive faster response times and service compared to non-contract customers.


 </p>
                </div>
            </Col>
        </Row>

        <Row>
            
            <Col lg={{span:6 , offset:3}} >
            <div className='advantageDiv'>
                    <h4>Peace of Mind</h4>
                    <p>Further, an AMC offers an regular maintainance and support that brings peace of mind. Clients can stay assured that their systems are operating optimally and providing the level of security that you require. </p>
                </div>
            </Col>
        </Row>
        
    </Container>
    </section> 
        <Footer/>
        </div>
    );
} 
}
export default ProjectAmc;