import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class FireAlarm extends Component {

    componentDidMount(){
        document.title = "Fire Alarm & PA System | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Fire Alarm & PA System' subtitle='' BkImg={config.ImageUrl + "System-Integration-Banner.jpg"} /> 

<Container className=" py-5 ProductsSection ">
<div className='text-center'>
        <h1 className="  py-5  mt-0">Fire Alarm System </h1>

        </div>
     <Row >
        <Col lg="12">
        <p>        Our company's fire alarm systems are designed to meet the specific needs of different types of buildings, from small offices to large industrial facilities.</p> 
 
       <p>   Our team of experts will work with clients to understand their unique requirements, conduct a comprehensive risk assessment, and design a customized fire alarm system that meets their needs.
</p>
<p>  Our fire alarm systems may include smoke detectors, heat detectors, carbon monoxide detectors, sprinklers, and other components that work together to provide reliable protection against fire. Our team will install, test, and maintain the fire alarm system to ensure that it is always in optimal working condition.</p>

<p>  Overall, our company's fire alarm systems play a crucial role in safeguarding people and property against the devastating effects of fire. By providing reliable, customized, and comprehensive fire alarm solutions, your company is helping to create safer environments for everyone.</p>


                              </Col>
       

    </Row>

  
</Container>


 <section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Public Address Systems</h1>

        </div>
        <Row>
            <Col lg="12">

          <p> Our expertise lies in designing and installing top-of-the-line systems for public spaces, including stadiums, arenas, and auditoriums.</p> 

          <p>With our reliable systems in place, you can feel confident in your ability to communicate important information during any emergency. Our state-of-the-art technology ensures that your message will be delivered with clarity and power, allowing you to reach even large crowds quickly and effectively. </p>
          <p>In addition to emergency situations, our systems can also be used to stay in contact with your workforce or make general announcements. At Public Address Systems, we are committed to keeping you and your audience safe and informed. </p>

          <p><b>Ensure the safety of your people with an emergency system. </b></p>

          <p>In today's unpredictable world, safety should always be a top priority. It's essential to have reliable emergency systems in place to protect the well-being of the people in your organization. A public address system (PA system) is a crucial component that can provide an added layer of safety in buildings, schools, or offices.</p>

          <p>By investing in a high-quality PA system, you can ensure that your messages are projected clearly and effectively. It's essential to have a system that can deliver clear sound quality in emergency situations where every second counts. Additionally, longevity is key when it comes to PA systems, as you want to ensure that it will last for years to come and not fail when you need it most.</p>

          <p>Overall, a reliable PA system is an investment in the safety and security of your people. With the ability to project your voice and communicate emergency messages clearly, it can provide peace of mind and protection for all.</p>

             
            </Col>
        
        </Row>

    </Container>
    </section>  

   
        <Footer/>
        </div>
    );
} 
}
export default FireAlarm;