import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Turnkey extends Component {

    componentDidMount(){
        document.title = "Turnkey Project Systems | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Turnkey Project Systems' subtitle='' BkImg={config.ImageUrl + "Project-Turnkey.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        Turnkey security systems offer end-to-end solutions and are ready-to-use systems that can provide a high level of security for commercial and residential spaces. These systems are comprised of cameras, sensors, alarms, and other tools. Turnkey security systems involve a one-time cost for installation thus making them more affordable than traditional customized security systems.



</p> 

<p>These systems are pre-programmed and come with detailed instructions for installation and operation. Many turnkey systems also offer remote monitoring and control, allowing owners to monitor and control their security systems from their phones, tablets, or computers.

</p>

<p>CAMline provide Turnkey solutions to Logistic, 3PL companies who are facing issues for sortuing right vendor for specific work, mostly in rural areas.

</p>
 
   
                              </Col>
       

    </Row>

  <Row className='pt-5'>
    <Col sm={{offset:2, span:8}}>     <Image src={config.ImageUrl + "Turnkey-infography-min.jpg"} fluid></Image>
 </Col>
  </Row>
</Container>

    
        <Footer/>
        </div>
    );
} 
}
export default Turnkey;