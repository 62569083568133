import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class Security_serv extends Component {

    componentDidMount(){
        document.title = "Security Surveillance Systems | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Security Surveillance Systems' subtitle='' BkImg={config.ImageUrl + "System-Integration-Banner.jpg"} /> 
        <Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        Our team of specialists has been dedicated to providing state-of-the-art surveillance hardware and software solutions. With our extensive experience in designing, installing, and maintaining a variety of systems, we have the expertise to ensure the safety and security of your corporate offices or hotels. Trust us to deliver reliable and efficient surveillance technologies that will give you peace of mind.
</p> 
 
   
                              </Col>
       

    </Row>

  
</Container>


 <section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">Unlock the Power of Intelligent Video Analytics: <br className='d-block d-sm-none'></br> Let AI Keep You Safe</h1>

        </div>
        <Row>
            <Col lg="12">
                <div className='advantageDiv'>
                     <p>In today's world, personal safety is more important than ever before. The good news is that with advancements in technology, we can now leverage the power of AI to enhance our security. Specifically, intelligent video analytics can help identify potential threats and create alarms based on those threats. By letting AI do the work, you can enjoy greater peace of mind knowing that you have an extra layer of protection. Don't wait any longer to take advantage of this cutting-edge technology. Harness the power of intelligent video analytics today and keep yourself and your loved ones safe.</p>
                </div>
            </Col>
        </Row>
    
    </Container>
    </section>  


    <Container className=' pb-5  HowCamline' >
    <div className='text-center'>
        <h1 className="  py-5  mt-0">How CAMline Works</h1>

        </div>
        <Row>
            <Col lg={{span:6 }}>  
            
                <Fade direction='up'  delay="40" triggerOnce="true">
  
            <li>	Ability to track individuals as they approach, enter or leave premises</li>
        <li>	Assist with on premise loss prevention measures</li>
        <li>	Reduce the threat of fraudulent claims</li>
        <li>	Ensure employee productivity</li>
        <li>	Ensure employee safety        </li>


                 </Fade>
                     
            </Col>
            <Col   lg="6">
                
                  <Fade direction='up'  delay="40" triggerOnce="true">
            
        <li>	Easily monitored and controlled from a centralized security office</li>
        <li>	Detect criminal behaviour and activity</li>
        <li>	Reduce physical security labour costs</li>
        <li>	Meets required security measure for certain types of properties</li>
        <li>	Record threat and incident information        </li>


 
                 </Fade>
                     
            </Col>
          
        </Row>
        </Container>     
        <Footer/>
        </div>
    );
} 
}
export default Security_serv;