import React, { Component } from 'react';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import SubHeader from '../layout/subheader';
import { Table } from 'react-bootstrap';

class ProductsPage extends Component {

    render(){
        return(
            <div>
          <SubHeader  Title='Granular Activated Carbon' subtitle='' BkImg='assets/images/Babji/Granular-Banner.png'/> 

                <Container className=" py-5 ProductsSection ">
                    <h1 className=" pb-3  mt-0">Granular Activated Carbon</h1>
                    <Row >
                        <Col lg="8">
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, sit, eum nam sequi, quod ullam ipsa consequuntur officia tempore voluptates similique voluptatibus. Recusandae assumenda explicabo, ab reprehenderit quam provident excepturi! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequuntur nam laudantium voluptates, error perspiciatis dolores dicta, a harum cum mollitia ipsum inventore at cumque autem placeat modi iusto tenetur quaerat!Lorem ipsum dolor sit amet consectetur adipisicing elit. Ea, sit, eum nam sequi, quod ullam ipsa consequuntur officia tempore voluptates similique voluptatibus. Recusandae assumenda explicabo, ab reprehenderit quam provident excepturi! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequuntur nam laudantium voluptates, error perspiciatis dolores dicta, a harum cum mollitia ipsum inventore at cumque autem placeat modi iusto tenetur quaerat!
                            </p> 
                            <p>voluptates similique voluptatibus. Recusandae assumenda explicabo, ab reprehenderit quam provident excepturi! Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consequuntur nam laudantium voluptates, error perspiciatis dolores dicta, a harum cum mollitia ipsum inventore at cumque autem placeat modi iusto tenetur quaerat!</p>
                                             </Col>
                       <Col lg="2" >
                            <h3 className='secondHeading text-center mb-3'>Applications</h3>

                            <ul>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                            </ul>
                                              </Col>

                                             <Col lg="2" s>
                                             <h3 className='secondHeading text-center  mb-3'>Features</h3>
                                             <ul>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                                <li>lorem ipsum</li>
                            </ul>
                                              </Col>
                    </Row>

                  
                </Container>

                <div className='grayBg '>
                    <Container>
                    <Row className='py-5'>
                        <Table     bordered hover>
                            <thead>
                                <tr>
                                    <th>Properties</th>
                                    <th></th>
                                    <th>G-500</th>
                                    <th>G-600</th>
                                    <th>G-800</th>
                                    <th>G-900</th>
                                    <th>G-1000</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Appearance</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Moisture</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Hardness</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>pH</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Loadline</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>

                            </tbody>
                        </Table>
                    </Row>
                    </Container>
                </div>
            </div>
        );
    }

}

export default ProductsPage;