import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class AccessControl extends Component {

    componentDidMount(){
        document.title = "Access Control Systems | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Access Control Systems' subtitle='' BkImg={config.ImageUrl + "System-Integration-Banner.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        By implementing access control measures, you can ensure the safety and security of your business and its inhabitants. Access control is not merely a means of granting entry to a building; it is also a powerful tool for safeguarding your valuable data from unauthorized access. To determine the most suitable method for your needs, it is advisable to refer to your organization's access control policy. With access control systems, you can now effortlessly maintain the security of your business. Our comprehensive range of solutions caters to diverse industries, including manufacturing, IT, and healthcare, enabling you to retain complete command over the security of your premises.
</p> 
 
   
                              </Col>
       

    </Row>

  
</Container>


 <section className='advantageSection pb-5'>
    <Container>
        <div className='text-center'>
        <h1 className="  py-5  mt-0">What are Electronic Access Control Systems?</h1>

        </div>
        <Row>
            <Col lg="12">
                <div className='advantageDiv'>
                     <p>Electronic Access Control Systems are security solutions that offer round-the-clock protection and access management. By leveraging cutting-edge technology, these systems employ sophisticated locking mechanisms and user authentication protocols to bolster conventional security measures. They serve as the primary line of defense, allowing organizations to regulate entry to secure sections of their premises, such as buildings, rooms, cabinets, or drawers containing confidential or proprietary data. With an access control system in place, only individuals with authorized credentials are granted entry, ensuring the security of the restricted area.</p>
                </div>
            </Col>
        </Row>
    
    </Container>
    </section>  


    <Container className=' pb-5  HowCamline' >
    <div className='text-center'>
        <h1 className="  py-5  mt-0">How CAMline Works</h1>

        </div>
        <Row>
            <Col lg={{span:4 , offset:2}}>  
            
                <Fade direction='up'  delay="40" triggerOnce="true">
  
           <li>	Receive Enquiry   </li>
            <li>	Analysis Enquiry </li>
            <li>	Suggest Best solution </li>
            <li>	Offer Best lirice </li>
            <li>	Design Solution </li>
            <li>	Form liroject Team </li>
            <li>	Handing over sign off</li>
                 </Fade>
                     
            </Col>
            <Col   lg="4">
                
                  <Fade direction='up'  delay="40" triggerOnce="true">
             <li>Arrange Material</li>
            <li>Plan for Installation</li>
            <li>Execute the Project</li>
            <li>Complete the Testing & Commissioning</li>
            <li>Onsite training to Client</li>
            <li>Submit related document to client</li>
            <li>Project Closure. </li>
 
                 </Fade>
                     
            </Col>
          
        </Row>
        </Container>   
        <Footer/>
        </div>
    );
} 
}
export default AccessControl;