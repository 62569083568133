import React ,{ Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image'
import config from '../../constant';
import { NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ratio from 'react-bootstrap/Ratio';
import {Fade} from 'react-awesome-reveal';
import Mainmap from './MainMap';

class AboutDiv extends Component {
        render() {

    return(
        <>
    <Container className='AboutSec py-3'  >

        
        <Row className='py-5'>

       

            <Col lg="12" className='ps-sm-5'>
            <h1 className=" pb-3  mt-0  text-left">About CAMline - Premium Security Partner In India</h1>

                <p>CAMline is a leading provider of security solutions and services for businesses that prioritize security and positive customer experiences. With our commitment to quality and collaboration, we tailor our solutions to meet the unique needs of each client.</p>
                <p>Our team has extensive experience in deploying the latest technology in security and access control systems, video surveillance, and public address systems. We specialize in designing systems that collect incident information and take action to reduce the risk of damage to assets and harm to individuals in emergency situations.</p>
                <p>Our access control systems allow businesses to restrict and monitor access to sensitive areas, ensuring that only authorized personnel can enter. Our video surveillance systems use advanced technology to capture and analyze footage, providing businesses with real-time information about potential threats. Our public address systems can quickly broadcast emergency announcements, ensuring that everyone is informed and can take appropriate action.</p>
                <p>At CAMline, we take our responsibility to protect our customers seriously. We work closely with each client to understand their unique security needs and design solutions that are tailored to their specific requirements. Our team is committed to providing the best possible service, ensuring that our clients feel safe and secure at all times.</p>

               
            </Col>

            {/* <Col lg={{span:4,offset:1 }} >
                <Image src={config.ImageUrl+"About-us-Image.png"} fluid alt={config.SITE_NAME}></Image>
            </Col> */}
           
        </Row>


        <Row className='py-5'>

       

<Col  lg={{span:6 ,offset:1, order:2}} className='ps-sm-5'>
<h1 className=" pb-3  mt-0  text-left">Teamwork</h1>

    <p>The team at CAMline is a group of highly skilled professionals who are dedicated to providing exceptional security services to their clients. They specialize in access control systems, security surveillance systems, public address systems, and gating solutions. The team's expertise in these areas allows them to design, install, and maintain top-of-the-line security solutions that meet the unique needs of each client.</p>

   
</Col>

<Col lg={{span:5,  order:1 }} >
    <Image src={config.ImageUrl+"Team-Image.png"} fluid alt={config.SITE_NAME}></Image>
</Col>

</Row>
    </Container>

    
    <section className='process-items'>

        <Container className="py-5">
        <h1 className=" pb-3  text-center">Core Values</h1>
        <h4 className=" pb-4 text-center">The CAMline culture and brand are based on core values that guide our daily operations.</h4>

        <Row>
                <Col lg="4">
                    <div className="item">
                            <a href="#">
                            <div className="cardBox2">
                            <Image src={config.ImageUrl+"Leader-Green.png"}  className="img-top1" alt={config.SITE_NAME}/>
                            <Image src={config.ImageUrl+"Leader-White.png"}  className="img-top" alt={config.SITE_NAME} />
                            </div>
                                <h4>Leader</h4>
                                <p>Establishing a path forward for security by setting the highest level of global standards.</p>
                            </a>
                        </div>
                        
                    </Col>

                <Col lg="4">
                        <div className="item">
                            <a href="#">
                            <div className="cardBox2">
                            <Image src={config.ImageUrl+"Teamwork-Green.png"}  className="img-top1" alt={config.SITE_NAME}/>
                            <Image src={config.ImageUrl+"Teamwork-White.png"}  className="img-top" alt={config.SITE_NAME} />
                            </div>                                <h4>Teamwork</h4>
                                <p>The foundation of everything we do. A work ethic that encourages all CAMline staff to go that extra mile.</p>
                            </a>
                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="item">
                            <a href="#">
                            <div className="cardBox2">
                            <Image src={config.ImageUrl+"Continuous-Improvement-Green.png"}  className="img-top1" alt={config.SITE_NAME}/>
                            <Image src={config.ImageUrl+"Continuous-Improvement-White.png"}  className="img-top" alt={config.SITE_NAME} />
                            </div>                                   <h4>Continuous Improvement</h4>
                                <p>CAMline is dedicated to continually enhancing our accomplishments by making things better, smarter, more effective, and more efficient.  </p>
                            </a>
                        </div>
                    </Col>
            </Row>
        </Container>
    </section>

    <Container className='reasonsSec py-5'>
        <Row>
            <Col lg="12">
            <h1 className=" pb-3  mt-0  text-center">Mission & Vision</h1>
            

            </Col>
            
        </Row>

        <Row className='py-3'>
            <Col lg="6">
                 
                    <Image  src={config.ImageUrl+"mission.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Mission</h4>
                    <p>Building long term relationship with our customers by creating real values for them through our innovative products and services.</p>
                    </div>
             
            </Col>
            <Col lg="6">
            <Image  src={config.ImageUrl+"vision.png"} fluid alt={ config.SITE_NAME} />
                    <div className='text'>
                     <h4>Vision</h4>
                    <p>Our company vision is to offer exceptional, tailored security solutions at an affordable cost, supported around the clock.</p>
                    </div>
                    </Col>
        </Row>

        
         
    </Container>

    <section className='process-items'>
    <Container className="py-5">
        <h1 className=" pb-3  text-center">Company Journey</h1>
         

        <Row>
                <Col lg="12" className='container2'>

                <ul className="timeline">
    <li>
    <Fade direction='up'  delay="40" triggerOnce="true">
    <Image  src={config.ImageUrl+"2016.png"} fluid alt={ config.SITE_NAME} />
      <h3>2017</h3>
      <p>Camline Begins the Operation.</p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="80" triggerOnce="true">

    <Image  src={config.ImageUrl+"2017.png"} fluid alt={ config.SITE_NAME} />
<h3>2018</h3>
      <p>Built Business Engagement with Global Organisation to represnt their solutions in domestic market. </p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="120" triggerOnce="true">

    <Image  src={config.ImageUrl+"2018.png"} fluid alt={ config.SITE_NAME} />
      <h3>2019</h3>
      <p>Approached Branded Clients.</p>
      </Fade>

    </li>
    <li>
    <Fade direction='up'  delay="160" triggerOnce="true">

      <Image  src={config.ImageUrl+"2019.png"} fluid alt={ config.SITE_NAME} />
      <h3>2020</h3>
      <p> Expanded West & North India.</p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="200" triggerOnce="true">
      <Image  src={config.ImageUrl+"2020.png"} fluid alt={ config.SITE_NAME} />
      <h3>2021</h3>
      <p>PAN India Presence with Inhouse Support Team. </p>
      </Fade>
    </li>
    <li>
    <Fade direction='up'  delay="240" triggerOnce="true">

      <Image  src={config.ImageUrl+"2021.png"} fluid alt={ config.SITE_NAME} />
      <h3>2022</h3>
      <p>Working With 30+ OEM Partners. </p>
      </Fade>

    </li>

    <li>
    <Fade direction='up'  delay="240" triggerOnce="true">

      <Image  src={config.ImageUrl+"2021.png"} fluid alt={ config.SITE_NAME} />
      <h3>2023</h3>
      <p>Entered in Turnkey solutions for Logistic & 3PL Companies. </p>
      </Fade>

    </li>
     
  </ul>


                </Col>
                </Row>
                </Container>
</section>



      
        </>

    );
}
    }
export default AboutDiv;