import React, { Component ,useEffect }  from 'react';
import props from 'prop-types';

import Footer from '../components/layout/footer';
import Header from '../components/layout/header';
import AboutHome from '../components/section/about_home';
import CardSec from '../components/section/cards';
import Goal from '../components/section/goal';
import Service from '../components/section/service';
import Slider from '../components/section/slider';
import Owlslider from '../components/section/owlslider';
import AboutUs from '../components/section/about_us';
import BookingForm from '../components/section/booking_form';
import config from '../constant';
import ProductsPage from '../components/section/products';
import Container  from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
 import { Table } from 'react-bootstrap';
import SubHeader from '../components/layout/subheader';
import {Fade} from 'react-awesome-reveal';

 class GatingSolutions extends Component {

    componentDidMount(){
        document.title = "Gating Solutions | " + config.SITE_NAME;
      }

    render(){

    return(
        <div>
        <Header  />   
        <SubHeader  Title='Gating Solutions' subtitle='' BkImg={config.ImageUrl + "System-Integration-Banner.jpg"} /> 

<Container className=" py-5 ProductsSection ">
     <Row >
        <Col lg="12">
        <p>
        Our pedestrian barriers are the ultimate solution for businesses that demand security and efficiency. We offer a wide range of options to fit any industry, and we'll work with you to select the perfect solution based on the size of your workforce. Our monitoring and management systems are incredibly accurate, providing you with real-time information at all times. In the event of an emergency, our fire detection systems and traffic gating work seamlessly together to ensure a safe and quick evacuation. Our gating solution is particularly useful during busy times, as it helps to regulate the flow of traffic and gives your security team more time to assess potential threats.
</p> 
 
   
                              </Col>
       

    </Row>

  
</Container>

<Container className='py-4 text-center productsImage'>
<Row>
                <Col>
                <h1 className=" pb-3  mt-0  text-left">Products</h1>

                </Col>
                </Row>
    <Row>
        <Col lg="4">
            <Image src={config.ImageUrl+ "1-Turnstile.jpg"} fluid></Image>
            <p>Turnstile</p>
        </Col>
        <Col lg="4">
        <Image src={config.ImageUrl+ "3-full-height-turnstile.jpg"} fluid></Image>
            <p>Full Height Turnstile</p>
        </Col>
        <Col lg="4">
        <Image src={config.ImageUrl+ "4-Boom-Barrier.jpg "} fluid></Image>
            <p>Boom Barrier</p>
        </Col>
    </Row>

    <Row className='pt-xs-0 pt-sm-5'>
    <Col   lg={{span:4, offset:2}}>
    <Image src={config.ImageUrl+ "5-DFMD.jpg "} fluid></Image>
            <p> DFMD </p>
        </Col>
        <Col lg="4">
        <Image src={config.ImageUrl+ "2-Flap-Barrier.jpg "} fluid></Image>
            <p>  Flap Barrier </p>
        </Col>
    </Row>

</Container>


  
        <Footer/>
        </div>
    );
} 
}
export default GatingSolutions;