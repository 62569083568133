import logo from './logo.svg';
import './App.css';
import {BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/home';
import ErrorPage from './pages/404';
import About from './pages/about';
import Contact from './pages/contact';
 
import ProductsMain from './pages/productsMain';
 
import ScrollToTop from './ScrollToTop';
 
import SystemIntegration from './pages/system_integration';
import ProjectManagement from './pages/project_management';
import NetworkSolutions from './pages/network_solutions';
import Turnkey from './pages/turnkey';
import ProjectAmc from './pages/project_amc';
import AccessControl from './pages/access_control_system';
import Security_serv from './pages/security_surv';
import GatingSolutions from './pages/gating_solutions';
import FireAlarm from './pages/fire_alarm';
import Mainmap from './components/section/MainMap';
import Branches from './pages/Branches';
import Oem_partner from './pages/oem_partners';

function App() {
  return (
   <BrowserRouter>
      <ScrollToTop />

    <Routes>
      <Route path="/" element= {<Home />} />
      <Route path="/home" element= {<Home />} />
      <Route path="/contact" element= {<Contact />} />
      <Route path="/who_we_are" element= {<About/>} />
      <Route path="/system_integration" element= {<SystemIntegration/>} />
      <Route path="/consultation_project_management" element= {<ProjectManagement/>} />
      <Route path="/network_security_solutions" element= {<NetworkSolutions/>} />
      <Route path="/turnkey_project_systems" element= {<Turnkey/>} />
      <Route path="/project_amc" element= {<ProjectAmc/>} />
      <Route path="/products" element= {<ProductsMain/>} />
      <Route path="/access_control_systems" element= {<AccessControl/>} />
      <Route path="/security_surveillance_systems" element= {<Security_serv/>} />
       <Route path="/gating_solutions" element= {<GatingSolutions/>} />
      <Route path="/fire_alarm_and_pa_system" element= {<FireAlarm/>} />
      <Route path="/presence" element= {<Branches/>} />
      <Route path="/tech_partners" element= {<Oem_partner/>} />
     
        <Route path="*" element={<ErrorPage />} />

    </Routes>

   </BrowserRouter>
  );
}

export default App;
